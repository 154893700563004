import keyBy from 'lodash/keyBy';

import { CompositeMetricConfig, MetricItemConfig } from './types';
import { elevateElementary24Options } from './answerOptions';

export const elementary24AffirmingIdentitiesItemConfig: MetricItemConfig[] = [
  {
    label: 'affirming_identities_elem_matters',
    likertN: 5,
    minGood: 4,
    prompts: {
      en: 'My teacher shows us that everyone matters.',
      es: 'Mi maestro nos muestra que todos importan.',
    },
    options: elevateElementary24Options,
  },
  {
    label: 'affirming_identities_elem_important',
    likertN: 5,
    minGood: 4,
    prompts: {
      en: 'In this class, we learn important things about people like me.',
      es: 'En esta clase, aprendemos cosas importantes sobre personas como yo.',
    },
    options: elevateElementary24Options,
  },
];

export const elementary24AffirmingIdentitiesConfig: CompositeMetricConfig = {
  label: 'affirming-identities-elem',
  items: elementary24AffirmingIdentitiesItemConfig,
  itemIndex: keyBy(elementary24AffirmingIdentitiesItemConfig, 'label'),
};
