import {
  selectUserIsAdmin,
  useCurrentUserQuery,
  useProgramGetByParams,
} from 'models';

import { useUpdateRecentProgram } from './useUpdateRecentProgram';

import { FixedActionbarContainer } from '@perts/ui';
import { HomeTemporaryStyles } from './HomeTemporaryStyles';
import { HomeActionbar } from './HomeActionbar';
import { HomeRender } from './HomeRender';
import { ErrorMessageBox } from 'components';

export const Home = () => {
  const user = useCurrentUserQuery();

  // Update user with most recently viewed program.
  useUpdateRecentProgram();

  // Query for Programs.
  const { data: program } = useProgramGetByParams();

  if (user.isLoading || !user.isSuccess || !program) {
    return null;
  }

  const isAdmin = selectUserIsAdmin(user.data);

  if (!program.active && !isAdmin) {
    return (
      <ErrorMessageBox showLinkToHome={false}>
        This program is not available.
      </ErrorMessageBox>
    );
  }

  return (
    <HomeTemporaryStyles>
      <FixedActionbarContainer>
        <HomeActionbar mayAddNetworks={isAdmin} />
      </FixedActionbarContainer>

      <HomeRender user={user.data} />
    </HomeTemporaryStyles>
  );
};
