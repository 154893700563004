import { useMemo } from 'react';
import { get } from 'lodash';

import {
  CompositeMetricConfig,
  getAttributeValueName,
  getAttributeValueShortName,
} from '@perts/config';
import type {
  ExperienceByAttribute,
  ExperienceResults,
  MetricSampleResults,
} from '@perts/model';
import { Col, HelpText, Row, Text } from '@perts/ui';

import { useTerms } from 'components/TermsContext';
import { helpArticles } from 'config';
import {
  getCumulativeChange,
  getIsEquityGap,
  getRecentCycleValue,
} from 'utils';

import {
  DISAGGREGATE_BY_ALL,
  SHOW_BY_METRIC,
  SHOW_BY_METRIC_AND_QUESTIONS,
} from './constants';
import type { DemographicRow } from './types';
import { getAttrLabelsWithGaps } from './getAttrLabelsWithGaps';
import { getItemsWithGaps } from './getItemsWithGaps';
import { useStateFilterDisaggregateBy } from './useStateFilterDisaggregateBy';
import { DisaggregationTable } from './DisaggregationTable';
import { SelectFilterMulti } from './SelectFilterMulti';

type Props = {
  experienceData: ExperienceResults;
  metricConfig: CompositeMetricConfig;
  metricName: string;
  programLabel: string;
  sampleData: MetricSampleResults;
  sampleSizeTotal: number;
};

export const MetricExperience = ({
  experienceData,
  metricConfig,
  metricName,
  programLabel,
  sampleData,
  sampleSizeTotal,
}: Props) => {
  const terms = useTerms();
  const isFilterSelectMulti = true;
  const { disaggregateBy, setDisaggregateBy, showBy, setShowBy, applyToAll } =
    useStateFilterDisaggregateBy(isFilterSelectMulti);

  const getAttributeSample = (itemLabel: string, attributePath: string) =>
    itemLabel === 'composite'
      ? get(sampleData, ['composite', attributePath], 0)
      : get(sampleData, ['by_item', itemLabel, attributePath], 0);

  const getItemRow = (
    label: string,
    name: string,
    sampleSize: number,
    byAttr: ExperienceByAttribute,
  ): DemographicRow => {
    const cycleValuesAll = byAttr.all_participants;
    const ratedPositively = getRecentCycleValue(cycleValuesAll);

    return {
      cumulativeChange: getCumulativeChange(cycleValuesAll),
      name,
      isComposite: label === 'composite', // metric title will be bold
      label,
      ratedPositively,
      sampleSize,
      sampleSizeTotal,
      byAttribute: Object.entries(byAttr).map(
        ([attributePath, cycleValues]) => {
          const [attributeLabel, attributeValue] = attributePath.split('.');
          const recent = getRecentCycleValue(cycleValues);

          return {
            attributeLabel,
            attributePath,
            attributeValue,
            attributeValueName: getAttributeValueName(attributePath),
            attributeValueShortName: getAttributeValueShortName(attributePath),
            cumulativeChange: getCumulativeChange(cycleValues),
            isEquityGap: getIsEquityGap(ratedPositively, recent),
            ratedPositively: recent,
            sampleSize: getAttributeSample(label, attributePath),
          };
        },
      ),
    };
  };

  // Start with the composite score.
  const compositeRow = getItemRow(
    'composite',
    metricName,
    get(sampleData, ['composite', DISAGGREGATE_BY_ALL], 0),
    experienceData.composite,
  );

  // Add rows for each item.
  const itemRows = Object.entries(experienceData.by_item).map(
    ([itemLabel, byAttribute]) =>
      getItemRow(
        itemLabel,
        // We don't have a "name" for items. Instead, display the literal
        // prompt associated with the item.
        get(
          metricConfig,
          ['itemIndex', itemLabel, 'prompts', 'en'],
          '(unknown item)',
        ),
        get(sampleData, ['by_item', itemLabel, DISAGGREGATE_BY_ALL], 0),
        byAttribute,
      ),
  );

  // Sort item rows according to config.
  const itemLabels = metricConfig.items.map((item) => item.label);
  itemRows.sort(
    (a, b) => itemLabels.indexOf(a.label) - itemLabels.indexOf(b.label),
  );

  /**
   * Note: We are grouping the "Questions" option under the "Disaggregate By"
   * section of options. All other "Disaggregate By" options are `metricOnly`
   * and all other single-level filters contain all `metricOnly` options.
   *
   * Internally the "Questions" option here is a `metricOnlyAndQuestion` type
   * option. This causes an initialization and syncing (apply to all) issue.
   *
   * In the case of Apply to All "(None)" that the single-level filters
   * contain, those are `metricOnly`, but here we want that to equate to the
   * "Questions" option, which is `metricOnlyAndQuestion`. When a sync occurs,
   * which happens on initialization and when clicking Apply to All, this filter
   * is to set to `metricOnly` and `all_participants`, which results in the
   * "Questions" option displaying without the questions.
   *
   * So, manually adjust the cases in which the data rendered includes the
   * question rows of data.
   *
   * See https://github.com/PERTS/perts/issues/2537
   */
  const includeQuestions =
    showBy === SHOW_BY_METRIC_AND_QUESTIONS ||
    (showBy === SHOW_BY_METRIC && disaggregateBy === DISAGGREGATE_BY_ALL);

  const data: DemographicRow[] = useMemo(
    () => (includeQuestions ? [compositeRow, ...itemRows] : [compositeRow]),
    [compositeRow, itemRows, includeQuestions],
  );

  // Apply current filter state.
  // Note that the "All" subgroup should always be visible.
  const dataFiltered = useMemo(
    () =>
      data.map((row) => ({
        ...row,
        byAttribute: row.byAttribute.filter(({ attributeLabel }) =>
          [DISAGGREGATE_BY_ALL, disaggregateBy].includes(attributeLabel),
        ),
      })),
    [data, disaggregateBy],
  );

  const itemsWithGaps = getItemsWithGaps(itemRows);

  // This allows us to display correctly the equity gap indicators for the
  // simple/uncombined gender, race, etc., options in the select filter.
  const dataMetricOnly = [compositeRow];
  const attrLabelsWithGapsMetricOnly = getAttrLabelsWithGaps(dataMetricOnly);

  // This allows us to display correctly the equity gap indicators for the
  // combined question and gender, race, etc., options in the select filter.
  const dataCombined = [compositeRow, ...itemRows];
  const attrLabelsWithGapsCombined = getAttrLabelsWithGaps(dataCombined);

  return (
    <>
      <Row noPageBreakInside>
        <Col cols={6} vAlign="center">
          <Text as="h3" id="metric-experience-overview" includeMargin={false}>
            {metricName} by Subpopulation and/or Question
          </Text>
        </Col>
        <Col cols={6} vAlign="center" hAlign="flex-end">
          <HelpText articleId={helpArticles.resultsBySubpopulationGraph}>
            Tips for using this
          </HelpText>
        </Col>
      </Row>

      <Row noPageBreakInside>
        <Col cols={12}>
          <Row>
            <SelectFilterMulti
              programLabel={programLabel}
              showBy={showBy}
              setShowBy={setShowBy}
              disaggregateBy={disaggregateBy}
              setDisaggregateBy={setDisaggregateBy}
              applyToAll={applyToAll}
              questionsHaveGaps={itemsWithGaps.length > 0}
              attrLabelsWithGapsCombined={attrLabelsWithGapsCombined}
              attrLabelsWithGapsMetricOnly={attrLabelsWithGapsMetricOnly}
            />
          </Row>
        </Col>
      </Row>

      <DisaggregationTable
        data={dataFiltered}
        primaryHeader={terms.condition}
      />
    </>
  );
};
