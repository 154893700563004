/**
 * {
 *  uid: 'Participant_0123456789ab',
 *  short_uid: '0123456789ab',
 *  student_id: 'STUDENT12345',
 *  first_name: 'Viper',
 *  last_name: 'Jones',
 *  name: 'Viper Jones',
 *  classroom_id: 'Classroom_123456789ab',
 * }
 */

import moment from 'moment';
import uri from 'urijs';
import { TEAM_CLASSROOMS_PARTICIPATION_JWT } from './teams';
import Entity from 'services/Entity';
import { TRITON_URL_PREFIX } from './config';
import {
  fetchApi,
  fetchOptionsGET,
  generateFetchFunctions,
  getSpecialJwt,
} from './helpers';
import { gcloudFunctionUrl } from 'utils';

export interface ParticipantEntity extends Entity {
  student_id: string;
  classroom_ids: string[];
}

export const {
  // boilerplate
  get,
  query,
  queryByUser,
  post,
  postBatch,
  update,
  remove,

  // custom
  queryByClassroom,
  queryByTeam,
  queryParticipationByParticipant,
} = generateFetchFunctions('participants', {
  // overrides default remove function
  remove: (participant, classroomId) => {
    participant.classroom_ids = participant.classroom_ids.filter(
      (id) => id !== classroomId,
    );
    return update(participant);
  },

  queryByClassroom: (classroomId) => {
    const url = `${TRITON_URL_PREFIX}/api/classrooms/${classroomId}/participants`;
    const options = fetchOptionsGET();
    return fetchApi(url, options);
  },

  queryByTeam: (teamId) => {
    const url = `${TRITON_URL_PREFIX}/api/teams/${teamId}/participants`;
    const options = fetchOptionsGET();
    return fetchApi(url, options);
  },

  queryParticipationByParticipant: (code, startDate, endDate) => {
    // This cloud function provides multiple endpoints. See `routes` in
    // dwApi.ts.
    const url = uri(`${gcloudFunctionUrl('dwApi')}/participation`);

    url.setSearch({
      code,
    });

    const { startTime, endTime } = cycleDatesToResponseTimes(
      startDate,
      endDate,
    );

    if (startDate) {
      url.setSearch({ start_date: startTime });
    }

    if (endDate) {
      url.setSearch({ end_date: endTime });
    }

    const options = fetchOptionsGET();

    // To allow this request on Neptune, which knows nothing about team/class
    // permission, use the specialized jwt from when we retrived the team.
    const special = getSpecialJwt(TEAM_CLASSROOMS_PARTICIPATION_JWT);
    options.headers.Authorization = `Bearer ${special}`;

    return fetchApi(url.toString(), options);
  },
});

/**
 * Cycle dates have no time component. PERTS convention is to interpret them in
 * PST. This function then converts them to ISO 8601 in UTC for use in the API
 * for querying survey response times.
 * @param {string} startDate as YYYY-MM-DD
 * @param {string} endDate   as YYYY-MM-DD
 * @returns {Object} times
 * @returns {boolean} times.startTime string e.g. "2022-01-01T08:00:00Z"
 * @returns {boolean} times.endTime string representing endDate but advanced
 *     almost to the next day so that the date range is inclusive, e.g.
 *     "2022-02-02T07:59:59Z"
 */
export const cycleDatesToResponseTimes = (startDate, endDate) => {
  const timeZoneOffset = '-08:00'; // Pacific Standard Time

  return {
    startTime: moment
      // Parse in UTC to ignore client's locale, e.g. daylight savings.
      .utc(startDate)
      // Choose the beginning of the day (inclusive date range).
      .hour(0)
      .minute(0)
      .second(0)
      // Shift the time to our chosen timezone.
      // > Passing true will keep the same local time, but at the expense of
      // > choosing a different point in Universal Time.
      // https://momentjs.com/docs/#/manipulating/utc-offset/
      .utcOffset(timeZoneOffset, true)
      // Format in UTC ('Z' ending) for API consumption
      .utc()
      .format(),
    endTime: moment
      .utc(endDate)
      // Choose the end of the day (inclusive date range).
      .hour(23)
      .minute(59)
      .second(59)
      .utcOffset(timeZoneOffset, true)
      .utc()
      .format(),
  };
};
