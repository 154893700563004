import keyBy from 'lodash/keyBy';

import { CompositeMetricConfig, MetricItemConfig } from './types';
import { elevateElementary24Options } from './answerOptions';

export const elementary24ClassroomCommunityItemConfig: MetricItemConfig[] = [
  {
    label: 'classroom_community_elem_activities',
    likertN: 5,
    minGood: 4,
    prompts: {
      en: 'I get to know my classmates through classroom activities.',
      es: 'Me toca conocer a mis compañeros a través de actividades en la clase.',
    },
    options: elevateElementary24Options,
  },
  {
    label: 'classroom_community_elem_kind',
    likertN: 5,
    minGood: 4,
    prompts: {
      en: 'In this class, kids are kind to each other.',
      es: 'En esta clase, niños son amables unos con otros.',
    },
    options: elevateElementary24Options,
  },
];

export const elementary24ClassroomCommunityConfig: CompositeMetricConfig = {
  label: 'classroom-community-elem',
  items: elementary24ClassroomCommunityItemConfig,
  itemIndex: keyBy(elementary24ClassroomCommunityItemConfig, 'label'),
};
