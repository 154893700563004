// Switch toggles the state between on and off.
// Switch can be interdeterminate.
// When toggling from the indeterminate state, immediately toggle on.

/**
 * Although this component is called Switch, and visually looks like a switch,
 * for accessibility, this is still a checkbox. Elements with role="switch"
 * don't have indeterminate or mixed states.
 *
 * See https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles/Switch_role
 */

import { useEffect, useRef } from 'react';
import type { FormikErrors } from 'formik';
import {
  SwitchButton,
  SwitchHidden,
  SwitchLabel,
  SwitchLabelText,
} from './SwitchStyled';
import { InputFieldError } from '../InputFieldError';

export type SwitchProps = {
  // The id of the input element.
  id: string;
  // The name of the input element.
  name?: string;
  // The label of the input element.
  label: string;

  // If true, the input element will be required.
  required?: boolean;
  // Attributes applied to the input element.
  inputProps?: object;

  // If true, the component is checked.
  checked?: boolean;
  // If true, the switch will be disabled.
  disabled?: boolean;

  // See MDN for more details on the indeterminate state of checkboxes:
  // https://developer.mozilla.org/en-US/docs/Web/HTML/Element/Input/checkbox#htmlattrdefindeterminate
  // https://developer.mozilla.org/en-US/docs/Web/HTML/Element/Input/checkbox#indeterminate_state_checkboxes
  //
  // > If the `indeterminate` attribute is present on the `<input>` element
  // > defining a checkbox, the checkbox's value is neither `true` nor `false`,
  // > but is instead indeterminate, meaning that its state cannot be determined
  // > or stated in pure binary terms. This may happen, for instance, if the
  // > state of the checkbox depends on multiple other checkboxes, and those
  // > checkboxes have different values.
  // >
  // > Essentially, then, the `indeterminate` attribute adds a third possible
  // > state to the checkbox: "I don't know." In this state, the browser may
  // > draw the checkbox in grey or with a different mark inside the checkbox.

  // If true, the switch will be in the indeterminate state.
  indeterminate?: boolean;

  // The icon to display when the component is unchecked.
  checkedIcon?: React.ReactNode;
  // The icon to display when the component is checked.
  uncheckedIcon?: React.ReactNode;

  // The value of the component.
  value?: any;
  // Callback fired when the state is changed.
  onChange?: (event: React.FormEvent<HTMLInputElement>) => void;

  // Formik function to set touch field
  setFieldTouched?: (
    field: string,
    value: any,
    shouldValidate?: boolean,
  ) => Promise<void | FormikErrors<any>>;

  // Field error.
  error?: any;

  // Whether or not to reserve height for and display errors; default true.
  displayError?: boolean;
};

export const Switch = ({
  checked = false,
  indeterminate = false,
  disabled,
  onChange,
  setFieldTouched,
  id,
  name,
  label,
  error,
  displayError = false,
  ...rest
}: SwitchProps) => {
  // TODO Figure out the correct TypeScript. <HTMLInputElement> does not work.
  const checkRef = useRef<any>({ checked, indeterminate });

  useEffect(() => {
    checkRef.current.checked = checked;
    checkRef.current.indeterminate = indeterminate;
  }, [checked, indeterminate]);

  const handleOnChange = async (event: React.FormEvent<HTMLInputElement>) => {
    const eventCaptured = { ...event }; // prevent reference from changing

    // Mark field as touched.
    if (setFieldTouched && name) {
      await setFieldTouched(name, true, true);
    }

    // Then call provided onChange event handler.
    if (onChange) {
      onChange(eventCaptured);
    }
  };

  // https://developer.mozilla.org/en-US/docs/web/Accessibility/ARIA/Attributes/aria-checked
  const ariaChecked = checked ? true : indeterminate ? 'mixed' : false;

  return (
    <>
      <SwitchLabel disabled={disabled}>
        <SwitchHidden
          type="checkbox"
          id={id}
          name={name}
          ref={checkRef}
          onChange={handleOnChange}
          disabled={disabled}
          aria-checked={ariaChecked}
          {...rest}
          value="true"
        />

        <SwitchButton checked={checked} indeterminate={indeterminate} />

        <SwitchLabelText>{label}</SwitchLabelText>
      </SwitchLabel>

      {displayError && (
        <InputFieldError
          role="alert"
          aria-live="assertive"
          id={`${name}-switch-field-error`}
          data-testid={`${name}-text-field-error`}
        >
          {error}
        </InputFieldError>
      )}
    </>
  );
};
