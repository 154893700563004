import { sortBy } from 'lodash';
import { useState } from 'react';

import { programMetricIndex } from '@perts/config';
import { TeamResults } from '@perts/model';
import {
  Card,
  Col,
  HelpText,
  PageBreak,
  Row,
  Show,
  Text,
  useBroadcast,
} from '@perts/ui';

import { Class, Metric, Program } from 'models';
import { channelFullwindow, helpArticles } from 'config';
import { toClassReportSettings, useParams } from 'pages';
import { useTitle } from 'utils';
import {
  getEnabledMetrics,
  MetricSection,
  removeDisabledMetricsFromResults,
  ReportStyled,
} from '../components';
import {
  CommunicationFidelity,
  ReportNavigation,
  ExperienceOverview,
  SurveySampleOverviewTable,
} from '../';
import { ArchivedWarning } from 'components/ArchivedWarning';
import { ExperienceBySurveyChart } from 'components/ExperienceBySurveyChart';
import { useTerms } from 'components/TermsContext';
import ReportTitleRow from '../components/ReportTitleRow';

type Props = {
  archived: boolean;
  cls: Class;
  lastRun: string;
  program: Program;
  programMetrics: Metric[];
  reportMetrics: Metric[];
  results: TeamResults;
};

export const ClassReport23Render = ({
  archived,
  cls,
  lastRun,
  results: unfilteredResults,
  program,
  programMetrics,
  reportMetrics,
}: Props) => {
  const { groupId } = useParams();
  const terms = useTerms();

  useTitle(`${terms.class} Report: ${cls.name}`);

  const [isFullwindow, setFullwindow] = useState(false);
  const { listen } = useBroadcast(channelFullwindow);
  listen((shouldExpand) => setFullwindow(shouldExpand));

  const programMetricConfig = programMetricIndex[program.label];

  const enabledMetrics = getEnabledMetrics(cls, reportMetrics);

  const results = removeDisabledMetricsFromResults(
    unfilteredResults,
    enabledMetrics,
  );

  const overallDataByMetric = Object.entries(results.experience).map(
    ([metricLabel, experienceData]) =>
      experienceData.composite.all_participants,
  );
  const mostTimePointsCollected = Math.max(
    ...overallDataByMetric.map(
      (cycleValues) => cycleValues.filter((x) => x !== null).length,
    ),
  );

  const metricBySurvey = Object.entries(results.experience).map(
    ([metricLabel, metricResults]) => ({
      childName:
        enabledMetrics.find((m) => m.label === metricLabel)?.name || '',
      childLabel: metricLabel,
      ratedPositive: metricResults.composite.all_participants,
    }),
  );
  const metricBySurveySorted = sortBy(metricBySurvey, 'childName');

  return (
    <>
      <ReportStyled fullscreen={isFullwindow}>
        <ReportNavigation
          enabledMetrics={enabledMetrics}
          program={program}
          reportMetrics={reportMetrics}
          showOpenResponsesToggle
          toSettings={toClassReportSettings(groupId, cls.uid)}
        />

        <Show when={archived}>
          <ArchivedWarning isReport={true} />
        </Show>

        <ReportTitleRow>
          <Col cols={1}></Col>
          <Col cols={10}>
            <h1>Overall Results</h1>
          </Col>
        </ReportTitleRow>

        {results.fidelity && program.label !== 'sesStudent23' && (
          <section id="survey-fidelity">
            <CommunicationFidelity
              fidelityMakeBetter={results.fidelity.make_better}
            />
          </section>
        )}

        <section id="student-experience-overview">
          <ExperienceOverview
            experienceData={results.experience}
            metrics={enabledMetrics}
            programLabel={program.label}
            sampleData={results.sample}
          />
        </section>

        <PageBreak />
        <section id="metric-trends">
          <Card>
            <Card.Title>
              <Row noPageBreakInside>
                <Col cols={6}>
                  <Text as="h2">{terms.condition} Trends</Text>
                </Col>
                <Col cols={6} vAlign="center" hAlign="flex-end">
                  <HelpText
                    articleId={helpArticles.learningConditionTrendsTable}
                  >
                    Tips for using this
                  </HelpText>
                </Col>
              </Row>
            </Card.Title>

            <Card.Content>
              <Row noPageBreakInside>
                <Col>
                  <Show when={mostTimePointsCollected > 1}>
                    <ExperienceBySurveyChart
                      ariaLabel={`${terms.condition} trends by survey`}
                      experienceByChild={metricBySurveySorted}
                      customLegend
                    />
                  </Show>
                  <Show when={mostTimePointsCollected <= 1}>
                    <p>
                      <em>Not enough data collected yet.</em>
                    </p>
                  </Show>
                </Col>
              </Row>
            </Card.Content>
          </Card>
        </section>

        <PageBreak />
        <h1>Detailed Results</h1>

        {enabledMetrics.map((metric) =>
          metric ? (
            <div key={metric.label}>
              <MetricSection
                metric={metric}
                experienceData={results.experience[metric.label]}
                key={metric.label}
                openResponseResults={
                  (results.open_responses || {})[metric.label]
                }
                programLabel={program.label}
                sampleData={results.sample.by_metric[metric.label]}
                sampleSizeTotal={results.sample.all_responses.all_participants}
                programMetricConfig={programMetricConfig}
              />
              <PageBreak />
            </div>
          ) : null,
        )}

        <section id="survey-response-rates">
          <Card>
            <Card.Title>
              <Row>
                <Col cols={6}>
                  <Text as="h2">Participation by Subpopulation</Text>
                </Col>
                <Col cols={6} vAlign="center" hAlign="flex-end">
                  <HelpText
                    articleId={helpArticles.participationBySubpopulationTable}
                  >
                    Tips for using this
                  </HelpText>
                </Col>
              </Row>
            </Card.Title>

            <Card.Content>
              <SurveySampleOverviewTable
                groupId={groupId}
                lastRun={lastRun}
                programLabel={program.label}
                sampleData={results.sample.all_responses}
              />
            </Card.Content>
          </Card>
        </section>
      </ReportStyled>
    </>
  );
};
