// https://docs.google.com/spreadsheets/d/1EKKzZjI-eCtekB_S_SsNnBQpGEKVIKueZIkBD6W8QJc

import keyBy from 'lodash/keyBy';

import { CompositeMetricConfig, MetricItemConfig } from './types';

export const belongingCertainty22ItemConfig: MetricItemConfig[] = [
  {
    label: 'belong_certain',
    likertN: 6,
    likertReverse: true,
    minGood: 5,
    prompts: {
      en:
        `Sometimes I feel that I belong in this class, and sometimes I ` +
        `feel that I don’t belong.`,
      // Note: ascend does not currently support es locale.
      es: 'Not yet translated.',
    },
  },
];

export const belongingCertainty22Config: CompositeMetricConfig = {
  // 2024-08-27 CM changed this label from 'belonging-certainty' to
  // 'belonging-certainty-22' so it won't conflict with the current version,
  // which is not reversed. This older, reversed version is not in use by either
  // Copilot or Saturn.
  // Note that archived reports from ascend22 will reference this original
  // metric with the label 'belonging-certainty'.
  label: 'belonging-certainty-22',
  items: belongingCertainty22ItemConfig,
  itemIndex: keyBy(belongingCertainty22ItemConfig, 'label'),
};
