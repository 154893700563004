import { locales } from '../../i18n';

export const elevateElementary24Options = {
  [locales.ENGLISH]: [
    {
      label: '1 - Don’t agree at all',
      value: 1,
    },
    {
      label: '2 - Slightly agree',
      value: 2,
    },
    {
      label: '3 - Somewhat agree',
      value: 3,
    },
    {
      label: '4 - Mostly agree',
      value: 4,
    },
    {
      label: '5 - Completely agree',
      value: 5,
    },
  ],
  [locales.SPANISH]: [
    {
      label: '1 - Nada de acuerdo',
      value: 1,
    },
    {
      label: '2 - De acuerdo un poco',
      value: 2,
    },
    {
      label: '3 - De acuerdo más o menos',
      value: 3,
    },
    {
      label: '4 - De acuerdo bastante',
      value: 4,
    },
    {
      label: '5 - De acuerdo completamente',
      value: 5,
    },
  ],
};
