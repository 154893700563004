// Minimum participation rate within a cycle that counts as that cycle being
// "complete". To date used in:
//
// * triton-client in getMemberCycleStatus
// * triton-functions dwFreshsalesGenerator

export const cycleCompletionThreshold = 0.6;
export const cycleExcellentThreshold = 0.8;

export const dayOfWeekForSurveyResults = 1; // Monday

// Regular expressions to help define typical program behavior year over year.
export const programAscendPattern = /^(Program_)?ascend\d{2}$/;
export const programCatalyzePattern = /^(Program_)?catalyze\d{2}$/;
export const programElevatePattern = /^(Program_)?elevate\d{2}$/;
