import {
  dataIsEmpty,
  useClassesQueryByParams,
  useGroupGetByParams,
  useMetricsQuery,
  useProgramsQuery,
  selectGroupProgram,
  useCurrentUserQuery,
  selectUserIsAdmin,
  selectUserIsGroupManager,
  selectUserIsMember,
} from 'models';

import { useParams } from 'pages';

import { useSortBySurveys, ErrorMessageBox, Loading } from 'components';
import { SurveysRender } from './SurveysRender';
import { getMessageFromErrors } from '@perts/util';
import { orderBy } from 'lodash';

// eslint-disable-next-line complexity
export const Surveys = () => {
  const { groupId = '' } = useParams();

  const currentUserQuery = useCurrentUserQuery();

  const groupQuery = useGroupGetByParams();

  // Sorting
  const { SortBy, sortByField, sortByDirection } = useSortBySurveys();

  // Query for Programs.
  const {
    isLoading: programsIsLoading,
    isError: programsIsError,
    data: programs = [],
    error: programsError,
  } = useProgramsQuery();

  // Query for Classes associated with Group.
  const {
    isLoading: classesIsLoading,
    isError: classesIsError,
    data: classes = [],
    error: classesError,
  } = useClassesQueryByParams();

  // Query for Metrics
  const {
    isLoading: metricsIsLoading,
    isError: metricsIsError,
    data: metrics = [],
    error: metricsError,
  } = useMetricsQuery();

  // Display loading.
  const isLoading =
    groupQuery.isLoading ||
    programsIsLoading ||
    classesIsLoading ||
    metricsIsLoading ||
    currentUserQuery.isLoading;

  if (isLoading) {
    return <Loading />;
  }

  // Display any errors.
  if (
    !groupQuery.isSuccess ||
    programsIsError ||
    classesIsError ||
    metricsIsError ||
    !currentUserQuery.isSuccess
  ) {
    return (
      <ErrorMessageBox>
        {getMessageFromErrors([
          groupQuery.error,
          programsError,
          classesError,
          metricsError,
        ])}
      </ErrorMessageBox>
    );
  }

  if (dataIsEmpty(programs) || dataIsEmpty(metrics)) {
    return <ErrorMessageBox>Could not load program data.</ErrorMessageBox>;
  }

  // Determine group program.
  const program = selectGroupProgram(groupQuery.data, programs);

  const availableMetrics = metrics.filter(({ uid }) =>
    program.metrics.find((m) => m.uid === uid),
  );

  const currentUser = currentUserQuery.data;

  const userIsMember = selectUserIsMember(currentUser, groupId);
  const userIsAdmin = selectUserIsAdmin(currentUser);
  const userIsManager = selectUserIsGroupManager(currentUser, groupId);

  const classesSorted = orderBy(
    // Add metricsLength so that we can utilize lodash's orderBy.
    classes.map((cls) => ({
      ...cls,
      metricsLength: cls.metrics.length,
    })),
    // Always secondary sort by name even when sorting by metrics.
    [sortByField, 'name'],
    [sortByDirection, 'asc'],
  );

  return (
    <>
      <SurveysRender
        classes={classesSorted}
        group={groupQuery.data}
        mayEdit={
          Boolean(program.survey_config_enabled) &&
          (userIsMember || userIsAdmin)
        }
        mayEditDefaultSettings={
          Boolean(program.survey_config_enabled) &&
          (userIsManager || userIsAdmin)
        }
        metrics={availableMetrics}
        program={program}
        SortByComponent={SortBy}
      />
    </>
  );
};
