import keyBy from 'lodash/keyBy';

import { CompositeMetricConfig, MetricItemConfig } from './types';
import { elevateElementary24Options } from './answerOptions';

export const elementary24WellOrganizedClassItemConfig: MetricItemConfig[] = [
  {
    label: 'well_organized_class_elem_find',
    likertN: 5,
    minGood: 4,
    prompts: {
      en: 'I know where to find everything in my classroom.',
      es: 'Sé donde encontrar todo en mi clase.',
    },
    options: elevateElementary24Options,
  },
  {
    label: 'well_organized_class_elem_reviews',
    likertN: 5,
    minGood: 4,
    prompts: {
      en: 'My teacher reviews my work and gives it back to me.',
      es: 'Mi maestro revisa mi trabajo y me lo devuelve.',
    },
    options: elevateElementary24Options,
  },
  {
    label: 'well_organized_class_elem_know',
    likertN: 5,
    minGood: 4,
    prompts: {
      en: 'Most of the time I know what I’m supposed to be doing in this class.',
      es: 'La mayoría del tiempo sé lo que debería estar haciendo en esta clase.',
    },
    options: elevateElementary24Options,
  },
];

export const elementary24WellOrganizedClassConfig: CompositeMetricConfig = {
  label: 'well-organized-class-elem',
  items: elementary24WellOrganizedClassItemConfig,
  itemIndex: keyBy(elementary24WellOrganizedClassItemConfig, 'label'),
};
