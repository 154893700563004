export * from './types';

import keyBy from 'lodash/keyBy';

// ascendMetrics
import { belongingCertainty22Config } from './belongingCertainty22';
import { belongingCertaintyConfig } from './belongingCertainty';
import { identitySafetyConfig } from './identitySafety';
import { institutionalGmsConfig } from './institutionalGms';
import { selfEfficacyConfig } from './selfEfficacy';
import { socialBelongingConfig } from './socialBelonging';
import { socialConnectednessConfig } from './socialConnectedness';
import { trustFairnessConfig } from './trustFairness';

// catalyzeMetrics
import { collectiveVision23Config } from './collectiveVision23';
import { effectiveSystemsConfig } from './effectiveSystems';
import { inclusiveLeadershipConfig } from './inclusiveLeadership';
import { learningCulture23Config } from './learningCulture23';
import { transformativeEquity23Config } from './transformativeEquity23';
import { trustingCommunity23Config } from './trustingCommunity23';

// elevateMetrics
import { affirmingIdentitiesConfig } from './affirmingIdentities';
import { classroomCommunityConfig } from './classroomCommunity';
import { feedbackForGrowthConfig } from './feedbackForGrowth';
import { learningGoalsConfig } from './learningGoals';
import { meaningfulWorkConfig } from './meaningfulWork';
import { studentVoiceConfig } from './studentVoice';
import { supportiveTeachingConfig } from './supportiveTeaching';
import { teacherCaringConfig } from './teacherCaring';
import { wellOrganizedClassConfig } from './wellOrganizedClass';

// Elevate Math Pilot 22 Metrics
import { mathPilot22AutonomyOverProcessConfig } from './mathPilot22AutonomyOverProcess';
import { mathPilot22CollaborativeLearningConfig } from './mathPilot22CollaborativeLearning';
import { mathPilot22LearningFromMisconceptionsConfig } from './mathPilot22LearningFromMisconceptions';
import { mathPilot22MathIdentityConfig } from './mathPilot22MathIdentity';
import { mathPilot22RelevanceOfMathConfig } from './mathPilot22RelevanceOfMath';

// Elevate Elemtary 24 Metrics
import { elementary24AffirmingIdentitiesConfig } from './elementary24AffirmingIdentities';
import { elementary24ClassroomCommunityConfig } from './elementary24ClassroomCommunity';
import { elementary24FeedbackForGrowthConfig } from './elementary24FeedbackForGrowth';
import { elementary24MeaningfulWorkConfig } from './elementary24MeaningfulWork';
import { elementary24StudentVoiceConfig } from './elementary24StudentVoice';
import { elementary24TeacherCaringConfig } from './elementary24TeacherCaring';
import { elementary24SupportiveTeachingConfig } from './elementary24SupportiveTeaching';
import { elementary24WellOrganizedClassConfig } from './elementary24WellOrganizedClass';

// Student Experience Study (ESSA-2 Quasi-experiment)
import { facultyMindsetBeliefsConfig } from './facultyMindsetBeliefs';
// trustingCommunity23Config, // same as in catalyze23
// learningCulture23Config, // same as in catalyze23
import { perspectiveTakingConfig } from './perspectiveTaking';
import { relationshipEfficacyConfig } from './relationshipEfficacy';
import { authoritarianDisciplineBeliefsConfig } from './authoritarianDisciplineBeliefs';
import { teacherStudentVoiceConfig } from './teacherStudentVoice';
import { teacherBurnoutConfig } from './teacherBurnout';
import { attitudesTowardsStudentsConfig } from './attitudesTowardsStudents';
import { growthMindsetConfig } from './growthMindset';
import { schoolBelongingConfig } from './schoolBelonging';
import { schoolIdentitySafetyConfig } from './schoolIdentitySafety';
import { effortConfig } from './effort';
import { schoolStudentVoiceConfig } from './schoolStudentVoice';
import { sesStudent23MathConfig } from './sesStudent23Math';

import type { ProgramMetricConfig, ProgramMetricIndex } from './types';
import { sesStudent23EnglishConfig } from './sesStudent23English';
import { sesStudent23ScienceConfig } from './sesStudent23Science';
import { sesStudent23HistoryConfig } from './sesStudent23History';

const ascend22Metrics = [
  belongingCertainty22Config,
  identitySafetyConfig,
  institutionalGmsConfig,
  selfEfficacyConfig,
  socialBelongingConfig,
  socialConnectednessConfig,
  trustFairnessConfig,
];
const ascendMetrics = [
  belongingCertaintyConfig,
  identitySafetyConfig,
  institutionalGmsConfig,
  selfEfficacyConfig,
  socialBelongingConfig,
  socialConnectednessConfig,
  trustFairnessConfig,
];

// https://docs.google.com/spreadsheets/d/1ZX4hcUol-_frFLfUYRnZ-UPEvpGsVOsWsJRw1g5tDBM
const catalyzeMetrics = [
  effectiveSystemsConfig,
  collectiveVision23Config,
  trustingCommunity23Config,
  learningCulture23Config,
  inclusiveLeadershipConfig,
  transformativeEquity23Config,
];

const elevateMetrics = [
  affirmingIdentitiesConfig,
  classroomCommunityConfig,
  feedbackForGrowthConfig,
  learningGoalsConfig,
  meaningfulWorkConfig,
  studentVoiceConfig,
  supportiveTeachingConfig,
  teacherCaringConfig,
  wellOrganizedClassConfig,
  // Math Pilot 22
  mathPilot22AutonomyOverProcessConfig,
  mathPilot22CollaborativeLearningConfig,
  mathPilot22LearningFromMisconceptionsConfig,
  mathPilot22MathIdentityConfig,
  mathPilot22RelevanceOfMathConfig,
  // Elementary Measures 24
  elementary24AffirmingIdentitiesConfig,
  elementary24ClassroomCommunityConfig,
  elementary24FeedbackForGrowthConfig,
  elementary24MeaningfulWorkConfig,
  elementary24StudentVoiceConfig,
  elementary24SupportiveTeachingConfig,
  elementary24TeacherCaringConfig,
  elementary24WellOrganizedClassConfig,
];

const sesTeacher23Metrics = [
  facultyMindsetBeliefsConfig,
  trustingCommunity23Config, // same as in catalyze23
  learningCulture23Config, // same as in catalyze23
  perspectiveTakingConfig,
  relationshipEfficacyConfig,
  authoritarianDisciplineBeliefsConfig,
  teacherStudentVoiceConfig,
  teacherBurnoutConfig,
  attitudesTowardsStudentsConfig,
];
const sesStudent23Metrics = [
  growthMindsetConfig,
  schoolBelongingConfig,
  schoolIdentitySafetyConfig,
  effortConfig,
  schoolStudentVoiceConfig,
  sesStudent23MathConfig,
  sesStudent23EnglishConfig,
  sesStudent23ScienceConfig,
  sesStudent23HistoryConfig,
];

export const programAscend22: ProgramMetricConfig = {
  metrics: ascend22Metrics,
  metricIndex: keyBy(ascend22Metrics, 'label'),
};

export const programAscend23: ProgramMetricConfig = {
  metrics: ascendMetrics,
  metricIndex: keyBy(ascendMetrics, 'label'),
};

export const programAscend24: ProgramMetricConfig = {
  metrics: ascendMetrics,
  metricIndex: keyBy(ascendMetrics, 'label'),
};

export const programCatalyze23: ProgramMetricConfig = {
  metrics: catalyzeMetrics,
  metricIndex: keyBy(catalyzeMetrics, 'label'),
};

export const programCatalyze24: ProgramMetricConfig = {
  metrics: catalyzeMetrics,
  metricIndex: keyBy(catalyzeMetrics, 'label'),
};

export const programElevate22: ProgramMetricConfig = {
  metrics: elevateMetrics,
  metricIndex: keyBy(elevateMetrics, 'label'),
};

export const programElevate23: ProgramMetricConfig = {
  metrics: elevateMetrics,
  metricIndex: keyBy(elevateMetrics, 'label'),
};

export const programElevate24: ProgramMetricConfig = {
  metrics: elevateMetrics,
  metricIndex: keyBy(elevateMetrics, 'label'),
};

export const programSesTeacher23: ProgramMetricConfig = {
  metrics: sesTeacher23Metrics,
  metricIndex: keyBy(sesTeacher23Metrics, 'label'),
};

export const programSesStudent23: ProgramMetricConfig = {
  metrics: sesStudent23Metrics,
  metricIndex: keyBy(sesStudent23Metrics, 'label'),
};

export const programMetricIndex: ProgramMetricIndex = {
  ascend22: programAscend22,
  ascend23: programAscend23,
  ascend24: programAscend24,
  catalyze23: programCatalyze23,
  catalyze24: programCatalyze24,
  elevate22: programElevate22,
  elevate23: programElevate23,
  elevate24: programElevate24,
  sesTeacher23: programSesTeacher23,
  sesStudent23: programSesStudent23,
};

export const getProgramMetricConfig = (programLabel: string) =>
  programMetricIndex[programLabel];
