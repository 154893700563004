import { sortBy } from 'lodash';
import { useState } from 'react';

import { programMetricIndex } from '@perts/config';
import type {
  OrganizationExperienceResults,
  NetworkResults,
} from '@perts/model';
import {
  Card,
  Col,
  HelpText,
  PageBreak,
  Row,
  Show,
  Text,
  useBroadcast,
} from '@perts/ui';

import { channelFullwindow, helpArticles } from 'config';
import { useTerms } from 'components/TermsContext';
import { Group, Network, Metric, Program } from 'models';
import { toNetworkReportSettings } from 'pages';
import { useTitle } from 'utils';
import {
  getEnabledMetrics,
  MetricSection,
  removeDisabledMetricsFromResults,
  ReportStyled,
} from '../components';
import { ExperienceBySurveyChart } from 'components/ExperienceBySurveyChart';
import {
  CommunicationFidelityNetwork,
  ExperienceOverview,
  ReportNavigation,
  SurveySampleOverviewTable,
} from '../';
import ReportTitleRow from '../components/ReportTitleRow';

type Props = {
  groups: Group[];
  lastRun: string;
  network: Network;
  program: Program;
  programMetrics: Metric[];
  reportMetrics: Metric[];
  results: NetworkResults;
  resultsByGroup: {
    [organizationId: string]: OrganizationExperienceResults;
  };
};

export const NetworkReport23Render = ({
  groups,
  lastRun,
  reportMetrics,
  network,
  program,
  results: unfilteredResults,
  resultsByGroup,
}: Props) => {
  const terms = useTerms();

  useTitle(`${terms.network} Report: ${network.name}`);

  const [isFullWindow, setIsFullWindow] = useState(false);
  const { listen } = useBroadcast(channelFullwindow);
  listen((shouldExpand) => setIsFullWindow(shouldExpand));

  const programMetricConfig = programMetricIndex[program.label];

  const enabledMetrics = getEnabledMetrics(network, reportMetrics);

  const results = removeDisabledMetricsFromResults(
    unfilteredResults,
    enabledMetrics,
  );

  const overallDataByMetric = Object.entries(results.experience).map(
    ([, experienceData]) => experienceData.composite.all_participants,
  );
  const mostTimePointsCollected = Math.max(
    ...overallDataByMetric.map(
      (cycleValues) => cycleValues.filter((x) => x !== null).length,
    ),
  );

  const metricBySurvey = Object.entries(results.experience).map(
    ([metricLabel, metricResults]) => ({
      childName:
        enabledMetrics.find((m) => m.label === metricLabel)?.name || '',
      childLabel: metricLabel,
      ratedPositive: metricResults.composite.all_participants,
    }),
  );
  const metricBySurveySorted = sortBy(metricBySurvey, 'childName');

  return (
    <ReportStyled fullscreen={isFullWindow}>
      <ReportNavigation
        enabledMetrics={enabledMetrics}
        program={program}
        reportMetrics={reportMetrics}
        showExport={true}
        toSettings={toNetworkReportSettings(network.uid)}
      />

      <ReportTitleRow>
        <Col cols={1}></Col>
        <Col cols={10}>
          <h1>Overall Results</h1>
        </Col>
      </ReportTitleRow>

      {resultsByGroup && program.label !== 'sesStudent23' && (
        <section id="survey-fidelity">
          <CommunicationFidelityNetwork
            groups={groups}
            resultsByGroup={resultsByGroup}
          />
        </section>
      )}

      <section id="student-experience-overview">
        <ExperienceOverview
          experienceData={results.experience}
          metrics={enabledMetrics}
          programLabel={program.label}
          sampleData={results.sample}
        />
      </section>

      <PageBreak />
      <section id="metric-trends">
        <Card>
          <Card.Title>
            <Row noPageBreakInside>
              <Col cols={6}>
                <Text as="h2">{terms.condition} Trends</Text>
              </Col>
              <Col cols={6} vAlign="center" hAlign="flex-end">
                <HelpText articleId={helpArticles.learningConditionTrendsTable}>
                  Tips for using this
                </HelpText>
              </Col>
            </Row>
          </Card.Title>

          <Card.Content>
            <Row noPageBreakInside>
              <Col>
                <Show when={mostTimePointsCollected > 1}>
                  <ExperienceBySurveyChart
                    ariaLabel={`${terms.condition} trends by survey`}
                    experienceByChild={metricBySurveySorted}
                    customLegend
                  />
                </Show>
                <Show when={mostTimePointsCollected <= 1}>
                  <p>
                    <em>Not enough data collected yet.</em>
                  </p>
                </Show>
              </Col>
            </Row>
          </Card.Content>
        </Card>
      </section>

      <PageBreak />

      <h1>Detailed Results</h1>

      {enabledMetrics.map((metric) =>
        metric ? (
          <div key={metric.label}>
            <MetricSection
              resultsByGroup={resultsByGroup}
              experienceData={results.experience[metric.label]}
              groups={groups}
              key={metric.label}
              metric={metric}
              network={network}
              programMetricConfig={programMetricConfig}
              programLabel={program.label}
              sampleData={results.sample.by_metric[metric.label]}
              sampleSizeTotal={results.sample.all_responses.all_participants}
            />
            <PageBreak />
          </div>
        ) : null,
      )}

      <section id="survey-response-rates">
        <Card>
          <Card.Title>
            <Row>
              <Col cols={6}>
                <Text as="h2">Participation by Subpopulation</Text>
              </Col>
              <Col cols={6} vAlign="center" hAlign="flex-end">
                <HelpText
                  articleId={helpArticles.participationBySubpopulationTable}
                >
                  Tips for using this
                </HelpText>
              </Col>
            </Row>
          </Card.Title>
          <Card.Content>
            <SurveySampleOverviewTable
              lastRun={lastRun}
              networkId={network.short_uid}
              programLabel={program.label}
              sampleData={results.sample.all_responses}
            />
          </Card.Content>
        </Card>
      </section>
    </ReportStyled>
  );
};
