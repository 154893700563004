import keyBy from 'lodash/keyBy';

import { CompositeMetricConfig, MetricItemConfig } from './types';
import { elevateElementary24Options } from './answerOptions';

export const elementary24StudentVoiceItemConfig: MetricItemConfig[] = [
  {
    label: 'student_voice_ideas_elem_better',
    likertN: 5,
    minGood: 4,
    prompts: {
      en: 'My teacher uses kids’ ideas to make our class better.',
      es: 'Mi maestro usa ideas de los niños para mejorar nuestra clase.',
    },
    options: elevateElementary24Options,
  },
  {
    label: 'student_voice_ideas_elem_seriously',
    likertN: 5,
    minGood: 4,
    prompts: {
      en: 'In this class, my thoughts and opinions are taken seriously.',
      es: 'En esta clase, mis pensamientos y opiniones se toman en serio.',
    },
    options: elevateElementary24Options,
  },
];

export const elementary24StudentVoiceConfig: CompositeMetricConfig = {
  label: 'student-voice-elem',
  items: elementary24StudentVoiceItemConfig,
  itemIndex: keyBy(elementary24StudentVoiceItemConfig, 'label'),
};
