// Stored in Cloud Datastore.

// # Considerations in design
//
// * Performance. Server and client should have to do as little work as possible
//   to read the correct dataset for a given use case.
//   - Viewing all data regarding a network
//   - Viewing all data regarding an org
//   - Viewing all data regarding your teams in an org
// * Simplicity in permissions. This cloud function should not need to be aware
//   of our permission structure, other than the dataset types below, keeping
//   all the permission logic in the python server. That server code should be
//   able to make relatively simple decisions about whether a user may or may
//   not view a dataset.

// Given those parameters, we choose to break up datasets into these types:
// * 'network' - all data about a network, combined. Includes data
//   aggregated to the whole network, and broken down by org. Includes both
//   participation and survey result data. Only viewable by network leads.
//   Loaded by the network participation dashboard and network report.
// * 'organization' - all participation and survey results data at the
//   organization level. Includes team-level participation for all related teams
//   and so is forbidden for mere org members. Does NOT include team-level
//   survey results since only team/class leads can see that. Loaded by
//   the both the organization participation dashboard and the organization
//   report **when viewed by** network leads community leads, and super admins.
// * 'organization-user' - Similar to the 'organization' data type except the
//   team-level participation data is limited to a certain user's relationships.
//   Includes the same organization-level survey results as the the
//   'organization' data type. Loaded by the both the organization participation
//   dashboard and the organization report **when viewed by** non-lead org
//   members.
// * 'team' - survey results for a single team. Separate from other datasets
//   because there are potentially many teams for a single org-user combination
//   and we don't want to force the user to load them all to view one page.
//   Loaded by team/class reports, only accessible to team/class leads.

// # Terminology
//
// What's being counted, or counted uniquely, can be very confusing. Here are
// how these variable names work.
//
// * "participants" are exactly what they are in the database: entries in the
//   `participant` table. They are scoped to team/class, exist as entries on the
//   class roster, and may not have submitted any survey responses. One human
//   may be represented by many participants across many classes.
// * "respondents" are participants who have submitted a survey response. One
//   human may count as multiple respondents. Note this generally does NOT line
//   up with the use of "respondent" displayed on the dashboard, which usually
//   means _distinct_ respondents. In this code we are explicit when we are
//   using a count of respondents with distinct student IDs.
// * distinct counts, like "participants_distinct", are always distinct by the
//   `student_id` field (the email address, student ID, or other identifier they
//   used when signing in to the survey), with the goal of representing a unique
//   count of people, regardless of how many times they appear in various
//   classes or orgs.

import zlib from 'zlib';

import { createUid, getShortUid } from '@perts/util';

import {
  NetworkResults,
  OrganizationExperienceResults,
  OrganizationResults,
  TeamResults,
  Results,
} from './DatasetResults';

// This type is to be used by NodeJS, server-side.
export enum DatasetType {
  network = 'network',
  organization = 'organization',
  organizationUser = 'organizationUser',
  team = 'team',
}

export type DatasetEntity = {
  uid: string;
  short_uid: string;
  created: Date;
  modified: Date;
  deleted: boolean;

  parent_id: string;
  user_id?: string;
  filename: string;
  // If defined, data is stored on Google Cloud Storage at this path.
  // If null, data is stored with entity in Cloud Datastore.
  gcs_path: string | null;
  content_type: 'application/json' | 'text/csv';
  // `compressed_data` should be defined when gcs_path is null.
  // Trying to parse compressed data? See
  // apps/triton-functions/dwDatasetTaskWorker/README.md
  compressed_data?: ArrayBuffer;
  // Relates to permission, e.g. only class leads may view class survey results.
  dataset_type: DatasetType;
  // Describes the contained data. Uses semver; major version changes mean they
  // won't work with older client code.
  version: string;
};

export type DatasetData = {
  created: string;
  dataset_type: DatasetType;
  last_run: string;
  last_updated_results?: string;
  name: string;
  parent_id: string;
  program_id: string;
  results: Results;
  results_by_organization?: {
    [organizationId: string]: OrganizationExperienceResults;
  };
  user_id?: string;
  version: string;
};

export type OrganizationRespondentsByCycle = {
  name: string;

  // Note: participation rate is respondents / participants
  // Note: `participants_by_cycles` is NOT replaceable by a single value for
  // the org, e.g. if some classes in the org have 3 cycles, and some have 4.
  participants_by_cycle: number[];
  respondents_by_cycle: number[];
  respondents_distinct_by_cycle: number[];
};

// Represents an aggregation of cycles across all user's classes in some org.
export type MemberCycle = {
  ordinal: number;
  max_participation_rate: number;
  min_start_date: string | null; // 'YYYY-MM-DD'
};

export type RateByMember = {
  user_id: string;
  name: string; // name of team member
  email: string; // email of team member
  member_cycles: MemberCycle[];
};

export type NetworkDataResults = {
  last_updated_results?: string;
  results: NetworkResults;
  results_by_organization: {
    [organizationId: string]: OrganizationExperienceResults;
  };
};

// Keyed by and scoped to: network
// Contents aggregation level: network, organization
export type NetworkData = DatasetData & {
  dataset_type: DatasetType.network;

  network_id: string;

  organizations: number;
  teams: number;

  team_members: number;
  team_members_active?: number; // added in v1.1.0;

  participants: number;
  respondents?: number; // added in v1.1.0;
  participants_distinct: number;
  respondents_distinct: number;

  // Chart: Respondents and Participation Rate by Survey
  // Note: participation rate is respondents / participants

  participants_by_cycle: number[];
  respondents_by_cycle: number[];

  // Chart: Class Leads Meeting x% Participation by Survey

  // Note: the network-wide `team_members` value is used as a denominator to
  // find % of leads meeting the threshold.
  team_members_by_cycle: number[];
  active_team_members_by_cycle: number[];

  // Table: # of Respondents
  // Table: % Participation Rate

  organization_respondents_by_cycle: OrganizationRespondentsByCycle[];

  // Table: # of Class Leads Meeting x% Participation
  // Table: % of Class Leads Meeting x% Participation

  organization_active_team_members_by_cycle: {
    name: string;
    active_team_members_by_cycle: number[];
    // Note: `team_members_by_cycle` is NOT replaceable by a single value for
    // the org, e.g. if some classes in the org have 3 cycles, and some have 4.
    team_members_by_cycle: number[];
  }[];
} & NetworkDataResults;

export type NetworkDataV1_1_0 = NetworkData & {
  respondents: number;
  team_members_active: number;
};

export type TeamRespondentsByCycle = {
  team_id: string;
  name: string;

  // Note: at the team level, all participants are unique, so there is no
  // need to count "distinct" participants.
  // Note: participation rate is respondents / participants
  participants_by_cycle: number[];
  respondents_by_cycle: number[];
};

export type OrganizationDataResults = {
  last_updated_results?: string;
  results: OrganizationResults;
};

// Keyed by and scoped to: organization
// Contents aggregation level: organization
export type OrganizationDataShared = {
  organization_id: string;

  team_members: number;
  teams: number;
  participants: number;
  participants_distinct: number;
  respondents?: number; // added in v1.1.0
  respondents_distinct: number;

  // Chart: Respondents Participation Rate by Survey
  participants_by_cycle: number[];
  respondents_by_cycle: number[];

  // Chart: Classes Meeting x% Participation by Survey
  teams_by_cycle: number[];
  active_teams_by_cycle: number[];

  // Table: % Participation Rate
  // Table: # of Respondents
  team_respondents_by_cycle: TeamRespondentsByCycle[];
} & DatasetData &
  OrganizationDataResults;

export type OrganizationData = OrganizationDataShared & {
  dataset_type: DatasetType.organization;

  // Table: % Participation rate by team member
  member_rate_by_cycle?: RateByMember[]; // added in v1.1.0
};

export type OrganizationDataV1_1_0 = OrganizationData & {
  respondents: number;
  member_rate_by_cycle: RateByMember[];
};

// Keyed by and scoped to: organization and user
// Contents aggregation level: organization and user
export type OrganizationUserData = OrganizationDataShared & {
  dataset_type: DatasetType.organizationUser;

  user_id: string;
};

export type TeamData = DatasetData & {
  dataset_type: DatasetType.team;
  parent_id: string;
  team_id: string;
  results: TeamResults;
};

// Mapped types

// Allow generic functions to pass type information about loaded datasets based
// on a DatasetType argument. See loadPayloads.ts.
export type DatasetDataByType<T extends DatasetType> = {
  [DatasetType.network]: NetworkData;
  [DatasetType.organization]: OrganizationData;
  [DatasetType.organizationUser]: OrganizationUserData;
  [DatasetType.team]: TeamData;
}[T];

// Type guards

type TypeGuard = (dataset: DatasetData) => boolean;

export const isNetworkData = (dataset: DatasetData): dataset is NetworkData =>
  dataset.dataset_type === DatasetType.network;

// This function intentionally does not test the version string exactly. It
// should return true for later versions that are backward compatible.
export const isNetworkDataV1_1_0 = (
  dataset: DatasetData,
): dataset is NetworkDataV1_1_0 =>
  dataset.dataset_type === DatasetType.network &&
  'respondents' in dataset &&
  'team_members_active' in dataset;

export const isOrganizationData = (
  dataset: DatasetData,
): dataset is OrganizationData =>
  dataset.dataset_type === DatasetType.organization;

// This function intentionally does not test the version string exactly. It
// should return true for later versions that are backward compatible. It
// should be possible to test the dataset version against semver syntax
// like '^1.1.0' but CM found that the standard semver package wouldn't install
// nicely.
export const isOrganizationDataV1_1_0 = (
  dataset: DatasetData,
): dataset is OrganizationDataV1_1_0 =>
  dataset.dataset_type === DatasetType.organization &&
  'respondents' in dataset &&
  'member_rate_by_cycle' in dataset;

export const isOrganizationUserData = (
  dataset: DatasetData,
): dataset is OrganizationUserData =>
  dataset.dataset_type === DatasetType.organizationUser;

export const isTeamData = (dataset: DatasetData): dataset is TeamData =>
  dataset.dataset_type === DatasetType.team;

// Allow lookup of the appropriate type guard based on a known DatasetType
// string. See loadPayloads.ts
export const DataTypeGuards: Record<DatasetType, TypeGuard> = {
  [DatasetType.network]: isNetworkData,
  [DatasetType.organization]: isOrganizationData,
  [DatasetType.organizationUser]: isOrganizationUserData,
  [DatasetType.team]: isTeamData,
};

// Utility functions

const datasetTypes: string[] = Object.values(DatasetType);

export const getDatasetId = (
  datasetType: DatasetType,
  parentId: string,
  userId?: string,
) => {
  if (!datasetTypes.includes(datasetType)) {
    throw new Error(`Unrecognized dataset type "${datasetType}".`);
  }

  const shortUid =
    datasetType === DatasetType.organizationUser
      ? `${datasetType}-${getShortUid(parentId)}-${getShortUid(userId || '')}`
      : `${datasetType}-${getShortUid(parentId)}`;

  return createUid('Dataset', shortUid);
};

export const getDatasetPayload = (dataset: DatasetEntity): DatasetData => {
  const jsonStr = zlib
    .inflateSync(dataset.compressed_data || new Buffer(0))
    .toString('utf8');
  return JSON.parse(jsonStr); // may throw errors if JSON malformed
};
