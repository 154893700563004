import keyBy from 'lodash/keyBy';

import { CompositeMetricConfig, MetricItemConfig } from './types';
import { elevateElementary24Options } from './answerOptions';

export const elementary24SupportiveTeachingItemConfig: MetricItemConfig[] = [
  {
    label: 'supportive_teaching_elem_explains',
    likertN: 5,
    minGood: 4,
    prompts: {
      en: 'My teacher explains things in different ways so that everyone understands.',
      es: 'Mi maestro explica cosas en diferentes maneras para que todos entiendan.',
    },
    options: elevateElementary24Options,
  },
  {
    label: 'supportive_teaching_elem_knows',
    likertN: 5,
    minGood: 4,
    prompts: {
      en: 'My teacher knows what I’m good at and what I need help with.',
      es: 'Mi maestro sabe en qué soy bueno y en qué necesito ayuda.',
    },
    options: elevateElementary24Options,
  },
];

export const elementary24SupportiveTeachingConfig: CompositeMetricConfig = {
  label: 'supportive-teaching-elem',
  items: elementary24SupportiveTeachingItemConfig,
  itemIndex: keyBy(elementary24SupportiveTeachingItemConfig, 'label'),
};
