import { useState } from 'react';

import {
  Col,
  Dropdown,
  IconChevronDown,
  IconExport,
  IconFilter,
  IconFullwindow,
  IconFullwindowExit,
  Link,
  Row,
  Show,
  useBroadcast,
} from '@perts/ui';

import {
  channelFullwindow,
  helpArticles,
  programCatalyzePattern,
} from 'config';
import { toNetworkReportExport, useParams } from 'pages';
import { Metric, Program } from 'models';
import { useTerms } from 'components/TermsContext';
import { IconButtonWrapper } from './IconButtonWrapper';
import { ItemLinkStyled } from './ItemLinkStyled';
import { ReportNavigationButton } from './ReportNavigationButton';
import { ReportNavigationCard } from './ReportNavigationCard';
import { ReportNavigationStyled } from './ReportNavigationStyled';
import OpenResponsesToggle from './OpenResponsesToggle';

const ArticleOrBookmark = ({ children, articleId, bookmark }) => {
  const fw = (window as any).FreshworksWidget;
  return articleId ? (
    <span onClick={() => fw('open', 'article', { id: articleId })}>
      {children}
    </span>
  ) : bookmark ? (
    <a href={bookmark}>{children}</a>
  ) : (
    children
  );
};

type MenuItemProps = {
  articleId?: string;
  bookmark?: string;
  link?: string;
  text: string;
};

const MenuItem = ({ articleId, bookmark, link, text }: MenuItemProps) => (
  <ItemLinkStyled>
    <ArticleOrBookmark articleId={articleId} bookmark={bookmark}>
      <Dropdown.Item>
        {link ? <Link to={link}>{text}</Link> : text}
      </Dropdown.Item>
    </ArticleOrBookmark>
  </ItemLinkStyled>
);

type ReportNavigationProps = {
  enabledMetrics: Metric[];
  program: Program;
  reportMetrics: Metric[];
  showExport?: boolean;
  showOpenResponsesToggle?: boolean;
  showTargetGroup?: boolean;
  toSettings: string | null;
};

export const ReportNavigation = ({
  enabledMetrics,
  program,
  reportMetrics,
  showExport = false,
  showOpenResponsesToggle = false,
  showTargetGroup = false,
  toSettings,
}: ReportNavigationProps) => {
  const { networkId } = useParams();
  const terms = useTerms();

  const [isFullwindow, setFullwindow] = useState(false);
  const { broadcast: broadcastFullwindow } = useBroadcast(channelFullwindow);

  const enabledMetricIds = enabledMetrics.map((m) => m.uid);
  const reportMetricIds = reportMetrics.map((m) => m.uid);
  const filteredMetricIds = reportMetricIds.filter(
    (id) => !enabledMetricIds.includes(id),
  );

  if (showExport && !networkId) {
    console.warn(
      'Cannot show export button in ReportNavigation; networkId not available.',
    );
    showExport = false;
  }

  const overallResultsMenu = [
    { bookmark: '#survey-fidelity', text: 'Communication Fidelity' },
    {
      bookmark: '#student-experience-overview',
      text: `${terms.participant} Experience Overview`,
    },
    {
      bookmark: '#metric-trends',
      text: `${terms.condition} Trends`,
    },
  ];

  const detailedResultsMenu = enabledMetrics.map((metric) => ({
    bookmark: `#${metric.label}-data`,
    text: metric.name,
  }));

  const participationMenu = [
    { bookmark: '#survey-response-rates', text: 'Survey Response Rates' },
  ];

  if (showTargetGroup) {
    participationMenu.push({
      bookmark: '#focal-group-summary',
      text: 'Focal Group Summary',
    });
  }

  const programName = program.name.toLocaleLowerCase();

  const howToUse = {
    articleId: helpArticles.howToUseReport,
    text: 'How to Use This Report',
  };
  const recommended = {
    link: helpArticles.recommendedPratices[programName],
    text: 'Recommended Practices',
  };
  const communityResources = {
    articleId: helpArticles.communityOfPracticeResources[programName],
    text: `${terms.group} of Practice Resources`,
  };
  const sharing = {
    articleId: helpArticles.sharingYourReport,
    text: 'Sharing Your Report',
  };
  const resourcesMenu = programCatalyzePattern.test(program.label)
    ? [howToUse, recommended, sharing]
    : [howToUse, recommended, communityResources, sharing];

  // May need to be adjusted if the content of the menu changes.
  // Important for allowing the menu to scroll if the viewport has low height.
  const dropdownHeightProps = {
    dropdownHeight: '65vh',
    dropdownMaxHeight: '510px',
  };

  return (
    <ReportNavigationStyled role="navigation">
      <ReportNavigationCard>
        <ReportNavigationCard.Content>
          <Row>
            <Col cols={8}>
              <IconButtonWrapper>
                {/* Maximize / Fullwindow */}
                <ReportNavigationButton
                  color={isFullwindow ? 'primary' : 'secondary'}
                  onClick={() => {
                    setFullwindow(!isFullwindow);
                    broadcastFullwindow(!isFullwindow);
                  }}
                  aria-label="maximize"
                >
                  {isFullwindow ? <IconFullwindowExit /> : <IconFullwindow />}
                </ReportNavigationButton>

                {/* Settings */}
                {toSettings && (
                  <ReportNavigationButton
                    aria-label="Report settings"
                    color={
                      filteredMetricIds.length !== 0 ? 'primary' : 'secondary'
                    }
                    to={toSettings}
                  >
                    <IconFilter />
                    <Show when={filteredMetricIds.length > 0}>
                      &ensp;
                      {filteredMetricIds.length} filtered
                    </Show>
                  </ReportNavigationButton>
                )}

                {/* Open Responses visibility (Only in Class reports) */}
                {showOpenResponsesToggle && <OpenResponsesToggle />}

                {/* Export CSV */}
                <Show when={Boolean(showExport)}>
                  <ReportNavigationButton
                    aria-label="Export data"
                    to={toNetworkReportExport(networkId)}
                    iconLeft={<IconExport />}
                    color="secondary"
                  >
                    Export
                  </ReportNavigationButton>
                </Show>
              </IconButtonWrapper>
            </Col>
            <Col hAlign="right" cols={4}>
              <Dropdown
                menuButton={
                  <>
                    <span>Contents</span> <IconChevronDown />
                  </>
                }
                color="secondary"
                fullWidth
                openDirection="right"
                {...dropdownHeightProps}
              >
                <Dropdown.Section>Overall Results</Dropdown.Section>
                {overallResultsMenu.map((config, i) => (
                  <MenuItem key={i} {...config} />
                ))}
                <Dropdown.Section>Detailed Results</Dropdown.Section>
                {detailedResultsMenu.map((config, i) => (
                  <MenuItem key={i} {...config} />
                ))}
                <Dropdown.Section>Participation</Dropdown.Section>
                {participationMenu.map((config, i) => (
                  <MenuItem key={i} {...config} />
                ))}
                <Dropdown.Section>Resources</Dropdown.Section>
                {resourcesMenu.map((config, i) => (
                  <MenuItem key={i} {...config} />
                ))}
              </Dropdown>
            </Col>
          </Row>
        </ReportNavigationCard.Content>
      </ReportNavigationCard>
    </ReportNavigationStyled>
  );
};
